<template>
    <div class="major-projects-table">
        <div class="by-begion-table">
            <el-table
                :data="tableData"
                border
                show-summary
            >
                <el-table-column
                    prop="name"
                    label="是否重点工程"
                >
                </el-table-column>
                <el-table-column
                    prop="gcsl"
                    label="工程数量(个)"
                >
                </el-table-column>
                <el-table-column
                    prop="yjfl"
                    label="预计方量(方)"
                >
                </el-table-column>
                <el-table-column
                    prop="yyfl"
                    label="已用方量(方)"
                >
                </el-table-column>
                <el-table-column
                    prop="ddsl"
                    label="订单数(个)"
                >
                </el-table-column>
                <el-table-column
                    prop="fhdsl"
                    label="发货单数量(个)"
                >
                </el-table-column>
            </el-table>
        </div>
        <div class="by-begion-echarts">
            <div class="by-begion-echarts-two">
                <h1>工程数量</h1>
                <div style="width:100%;height:360px" id="majorProjectsIdOne"></div>
            </div>
            <div class="by-begion-echarts-two">
                <div class="echarts-box-right">
                    <h1>方量对比</h1>
                    <span>单位(方)</span>
                </div>
                <div style="width:100%;height:360px" id="majorProjectsIdTwo"></div>
            </div>
        </div>
    </div>
</template>

<script>
// 是否重大工程
export default {
    data() {
        return {
            tableData: [],
            echartsName: [],
            echartsGcsl: [],
            echartsYjfl: [],
            echartsYyfl: [],
        };
    },
    props: {
        selectTimeData: {
            type: Array,
        },
    },
    mounted() {
        this.salesanalysisGclx();
    },
    methods: {
        majorProjectsOneFun() {
            const myChart = this.$echarts.init(document.getElementById('majorProjectsIdTwo'));
            const option = {
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '8%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['已用方量', '预计方量'],
                    x: 'right',
                    padding: [0, 20, 0, 0],
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                yAxis: {
                    data: this.echartsName,
                    type: 'category',
                    nameTextStyle: {
                        padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: '已用方量',
                        data: this.echartsYyfl,
                        type: 'bar',
                        barWidth: 15,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'right',
                            fontFamily: 'QuartzLTStd',
                        },
                    },
                    {
                        name: '预计方量',
                        data: this.echartsYjfl,
                        type: 'bar',
                        barWidth: 15,
                        color: '#F0AF56',
                        label: {
                            show: true,
                            fontFamily: 'QuartzLTStd',
                            position: 'right',
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        majorProjectsTwoFun() {
            const myChart = this.$echarts.init(document.getElementById('majorProjectsIdOne'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value;
                    },
                },
                xAxis: {
                    data: this.echartsName,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                },
                yAxis: {
                    axisLabel: {
                        color: '#fff',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: 'hill',
                        type: 'pictorialBar',
                        // 位置偏移
                        barCategoryGap: '50%',
                        // 图形宽度
                        barWidth: 80,
                        // 图形形状
                        symbol: 'path://M150 50 L130 130 L170 130  Z',
                        itemStyle: {
                            normal: {
                                opacity: 0.9,
                                color: function (params) {
                                    const colorList = [
                                        '#E44F4F', '#4CA295',
                                    ];
                                    return colorList[params.dataIndex];
                                },
                            },
                            emphasis: {
                                opacity: 1,
                            },
                        },
                        data: this.echartsGcsl,
                        z: 10,
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            fontSize: 20,
                        },
                    },
                    {
                        name: 'glyph',
                        type: 'pictorialBar',
                        barGap: '-100%',
                        symbolPosition: 'end',
                        symbolSize: 50,
                        symbolOffset: [0, '-120%'],
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 是否重大工程
        salesanalysisGclx() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/gcyl/sfzdgc?startDate=${this.selectTimeData[0]}&endDate=${this.selectTimeData[1]}`)
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.echartsName.push(item.name);
                        this.echartsGcsl.push(item.gcsl);
                        this.echartsYjfl.push(item.yjfl);
                        this.echartsYyfl.push(item.yyfl);
                    });
                    this.majorProjectsOneFun();
                    this.majorProjectsTwoFun();
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};

</script>
<style lang='stylus'>
.major-projects-table
    padding 0 .2rem .2rem .2rem
    .by-begion-table
        width 100%
        .el-table
            thead tr th
                background #EDF0F5
                color #022782
                font-weight 800
            td, th
                width 1rem
                padding 0
                text-align center
                font-size .16rem
                div
                    height .4rem
                    line-height .4rem
        .el-table__footer tr td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
    .by-begion-echarts
        display flex
        justify-content space-between
        margin-top .2rem
        .by-begion-echarts-two
            border 1px solid #D7D7D7
            flex 1
            .echarts-box-right
                display flex
                justify-content space-between
                span
                    color #979EA7
                    font-size .14rem
                    padding .2rem
            &:first-child
                margin-right .2rem
            h1
                color #333333
                font-size .18rem
                font-weight 800
                padding .2rem
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
</style>